import React, { useEffect, useState } from 'react';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink } from 'react-router-dom';

import './header.sass'


import { RiLogoutCircleRLine } from "react-icons/ri";
import { FaRegUser } from "react-icons/fa";
import clientLogo from '../images/club_logo.png'

const HeaderLine = () => {

  const personToken = sessionStorage.getItem('token')
  const personName = sessionStorage.getItem('name')

  
  return (
    <div className="header-line">
    <span className="client-logo">
      <img src={clientLogo} alt="Bem vindo!" />
    </span>

    {/* <ul>
    <li>
        <span className="sidebar-icon"><LiaUsersCogSolid/></span>
        <span className='item-content'>Relatórios</span>
    </li>
    </ul> */}

    <ul className='header-links'>
      <li>
          {personName || 'Visitante'}
      </li>
      <li onClick={() => {window.location.href= `/user/edit/me`}}>
          <span className="sidebar-icon"><FaRegUser/></span>
      </li>
      <li onClick={() => {
        sessionStorage.clear();
        window.location.href = '/'
      }}>
          <span className="sidebar-icon"><RiLogoutCircleRLine/></span>
      </li>
    </ul>
    </div>

  );
};


export default HeaderLine;