import React, { useState, useEffect } from 'react';
import { Button, Card, Modal} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { BsRepeat, BsTrash3 } from "react-icons/bs";
import InsertLogoModal from './insertLogoModal';

const SelectLogoModal = ({ showModal, handleModalClose}) => {

  //const [logos, setLogos] = useState([]);

  const [logos, setLogos] = useState([
    { id: 1, teamName: 'Time A', imageUrl: 'https://img.freepik.com/fotos-gratis/isolado-feliz-sorridente-cao-fundo-branco-retrato-4_1562-693.jpg?w=1060&t=st=1708551412~exp=1708552012~hmac=2be1b66f223c0652380768caafab9d09b48eb702092538db3b8b8cd102fb1caf' },
    { id: 2, teamName: 'Time B', imageUrl: 'https://img.freepik.com/fotos-gratis/adoravel-cachorro-basenji-marrom-e-branco-sorrindo-e-dando-mais-uns-cinco-isolado-no-branco_346278-1657.jpg?w=1060&t=st=1708551460~exp=1708552060~hmac=5d2f1d141eb1996299ffad4c48c25da25a9a3b0b26e9f95ab469bbdc49ecf0df' },
    { id: 3, teamName: 'Time C', imageUrl: 'https://img.freepik.com/fotos-gratis/close-de-um-cachorro-fofo-em-um-fundo-azul_181624-25204.jpg?w=360&t=st=1708551488~exp=1708552088~hmac=f39caf1fc4a30a4e0771471f449cfc6d467cf388bf1f565daec42010fb51911d' },
  ]);

  
  const [showInsertModal, setShowInsertModal] = useState(false);

  const handleInsertModalClose = () => {
    setShowInsertModal(false);
  };

  const handleOpenInsertModal = () => {
    setShowInsertModal(true);
  };

  const handleSelectLogo = (selectedLogo) => {
    console.log('Nome do Time:', selectedLogo.teamName);
    console.log('URL da Imagem:', selectedLogo.imageUrl);
    console.log("Escolheu o logo")
  };

  const handleReplaceLogo = (logo) => {
    console.log('Substituir logotipo:', logo);
  };

  const handleDeleteLogo = (logoId) => {
    console.log('Excluir logotipo com ID:', logoId);
  };

  const loadLogos = () => {
     // Função para pegar os logos
  };

  useEffect(() => {
    loadLogos();
  }, []);

  return (
    <Modal show={showModal} onHide={handleModalClose}>
      <Modal.Body>
        <Card>
          <Card.Header>
            <Card.Title as="h5">
              Escolha o logo do time visitante
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <table className="table table-bordered" style={{ borderColor: 'black' }}>
              <thead>
                <tr>
                  <th className='text-center border-0'>Time</th>
                  <th className='text-center border-0'>Logo</th>
                  <th className='text-center border-0'></th>
                </tr>
              </thead>
              <tbody>
                {logos.map((logo, index) => (
                  <tr key={index}>
                    <td className='text-center'
                      onClick={() => handleSelectLogo(logo)}
                      style={{ cursor: 'pointer', borderColor: 'black' }}>{logo.teamName}
                    </td>
                    <td className='text-center' style={{ borderColor: 'black' }}>
                      <img src={logo.imageUrl} alt={logo.teamName}
                      style={{ width: '50px', height: 'auto' }} />
                    </td>
                    <td className='text-center' style={{ borderColor: 'black' }}>
                      <button className="btn btn-secondary" onClick={() => handleReplaceLogo(logo)}><BsRepeat /></button>
                      <button className="btn btn-danger" onClick={() => handleDeleteLogo(logo)}><BsTrash3/></button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
                <div className="mb-3">
                  <Button className="btn btn-dark fs-5" onClick={handleOpenInsertModal}>
                    Inserir um novo logo de time
                  </Button>
                </div>
            <InsertLogoModal showModal={showInsertModal} handleModalClose={handleInsertModalClose}/>
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

SelectLogoModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
};

export default SelectLogoModal;
