import React, { useState } from 'react'
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  DropdownButton,
  FloatingLabel,
  Dropdown,
  Table,
  Alert
} from 'react-bootstrap'

import { auth } from "./../../config/firebase";

import { useForm } from 'react-hook-form'
import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import './visitorCreate.sass'

registerLocale('pt-br', ptBR)

import 'react-datepicker/dist/react-datepicker.css'
import { useParams } from 'react-router-dom';

// (00) 00000-0000
const maskPhone = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

// 00000-000
const maskCEP = (value: any) => {
  return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

// 00/00/0000
const maskDate = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1')
}

const isValidEmail = (email: any) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )

const strongPassword = (password: any) => {
  return /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
    password
  )
}

interface Props {
  eventId?: any
}

export function UserCreate({ eventId }: Props) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    getValues,
    reset
  } = useForm()

  const [startDate, setStartDate] = useState(new Date())

  const [hasToken, setHasToken] = useState('')

  const [disableSign, setDisableSign] = useState(false)

  const [newUser, setNewUser] = useState({
    login: '',
    password: '',
    set: false
  })

  function string_to_slug(str: any) {
    str = str.replace(/^\s+|\s+$/g, '') // trim
    str = str.toLowerCase()

    // remove accents, swap ñ for n, etc
    const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
    const to = 'aaaaeeeeiiiioooouuuunc------'
    for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '_') // collapse whitespace and replace by -
      .replace(/-+/g, '_') // collapse dashes

    return str
  }



  const signOut = async () => {
    try {
      await auth.signOut()
      return true
    } catch (error) {
      return false
    }
  };


  const quemta = () => {

    auth.onAuthStateChanged(() => {

      const user = auth.currentUser;
      if (user) {
        const uid = user.uid;
        console.log('minhapika', uid)
      } else {
        console.log('ta na milonga')
      }
    });

  }
  let { supplierId } = useParams()

  const onSubmit = (data: any) => {
    setDisableSign(true)

    const temp_name = data.name.replace(/^\s+|\s+$/g, '').split(' ')


    if (!supplierId) {
      supplierId = sessionStorage.getItem('company') || 'na'
    }


    fetch(`${BASE.api_user.base_url}${BASE.api_user.user_create}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': BASE.api.api_key
      },
      body: JSON.stringify({
        firstName: temp_name[0],
        lastName: temp_name.slice(1).join(" "),
        externalId: 'na',
        gender: '',
        document: data.document.replace(/^\s+|\s+$/g, ''),
        document_type: '',
        email: data.email.replace(/^\s+|\s+$/g, '') || 'a@a.com',
        phoneNumber: data.phoneNumber ?
          data.phoneNumber
            .replace(/"/g, '')
            .replace(/'/g, '')
            .replace(/\(|\)/g, '') : '000',
        birthDate: '1990-01-01',
        originCompanyId: supplierId,
        supplierId: supplierId,
        eventId,
        sendSms: true
      })
    })
      .then((res) => res.json())
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const resetForm = () => {
    setNewUser({
      login: '',
      password: '',
      set: false
    })
    setHasToken('')
    setDisableSign(false)
    reset()
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title as="h5" onClick={() => { quemta() }}>Cadastro Individual</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className="mb-3">
                <Col>
                  <Form.Label onClick={() => { signOut() }}>Nome</Form.Label>
                  <Form.Control
                    placeholder="Nome Completo"
                    {...register('name', { required: true })}
                  />
                  {errors.name && (
                    <span className="label-error">
                      Você precisa informar um Nome.
                    </span>
                  )}
                </Col>

                <Col>
                  <Form.Label>E-mail (opcional)</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="E-mail"
                    {...register('email')}
                  />
                  {errors.email && (
                    <span className="label-error">
                      Você precisa informar um E-mail válido
                    </span>
                  )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Label onClick={() => { signOut() }}>CPF</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Documento (CPF)"
                    {...register('document', { required: true })}
                  />
                  {errors.document && (
                    <span className="label-error">
                      Você precisa informar um CPF.
                    </span>
                  )}
                </Col>

                <Col>
                  <Form.Label>Telefone</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Celular"
                    {...register('phoneNumber')}
                    onChange={(event) => {
                      const { value } = event.target
                      event.target.value = maskPhone(value)
                    }}
                  />

                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Button
                      disabled={disableSign}
                      type="submit"
                      className="mb-0 w-100"
                      onClick={() => clearErrors()}
                    >
                      Cadastrar Usuário
                    </Button>
                  </Form.Group>
                </Col>

              </Row>
              {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
            </Form>
          </Col>
        </Row>

      </Card.Body>
    </Card>
  )
}
