import React, { useState } from 'react'
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    DropdownButton,
    Dropdown,
    Alert,
    Table,
    Modal
} from 'react-bootstrap'


import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'

import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'

import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import InputMask from 'react-input-mask';

interface Props {
    companyId: any
    supplierId: any
}

// (00) 00000-0000
const maskPhone = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}
export function CreateCoordinatorLink({ companyId, supplierId }: Props) {

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()

    const [currentSupplierId, setCurrentcurrentSupplierId] = useState(supplierId);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const createCoordinator = (data: any) => {

        const temp_name = data.name.replace(/^\s+|\s+$/g, '').split(' ')
        const temp_password = `tB${Math.random().toString(36).slice(-8)}!`


        fetch(`${BASE.api_user.base_url}${BASE.api_user.user_create}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                firstName: temp_name[0],
                lastName: temp_name.slice(1).join(" "),
                email: data.email.replace(/^\s+|\s+$/g, '') || 'a@a.com',
                phoneNumber: data.phoneNumber
                    .replace(/"/g, '')
                    .replace(/'/g, '')
                    .replace(/\(|\)/g, ''),
                "document": data.document.replace(/^\s+|\s+$/g, ''),
                "document_type": "CPF",
                "supplierId": data.supplierId,
                "originCompanyId": companyId,
                "externalId": "coordenador",
                "gender": "na",
                "birthDate": "1990-01-01"
            })
        })
            .then((res) => res.json())
            .then((response) => {

                fetch(`${BASE.api_user.base_url}${BASE.api_user.role}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': BASE.api.api_key
                    },
                    body: JSON.stringify({
                        "userId": response.sup,
                        "role": "coordinator",
                        "companyId": companyId,
                        "companyName": "BePass",
                        "email": data.email,
                        "name": data.name,
                        "login": data.email.replace(/^\s+|\s+$/g, ''),
                        "password": temp_password,
                        "sendMail": true
                    })
                })
                    .then((res) => res.json())
                    .then((response) => {

                        if (response.success) {

                            fetch(`${BASE.api.base_url}${BASE.api.access_create}`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'x-api-key': BASE.api.api_key
                                },
                                body: JSON.stringify({
                                    name: data.name.replace(/^\s+|\s+$/g, ''),
                                    email: data.email.replace(/^\s+|\s+$/g, ''),
                                    login: data.email.replace(/^\s+|\s+$/g, ''),
                                    password: temp_password,
                                    role: supplierId ? 'supplier' : 'coordinator',
                                    companyId,
                                    supplierId
                                })
                            })
                                .then((res) => res.json())
                                .then((response) => {
                                    window.location.reload();
                                })
                                .catch((error) => {
                                    console.log(error)
                                })

                        } else {
                            window.location.reload();
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })



            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <>
            <Link to="#" onClick={() => { handleShow() }} className="label theme-bg text-white f-12 m-l-15">
                Criar coordenador
            </Link>
            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit(createCoordinator)}>
                    <Form.Control
                        type="hidden"
                        defaultValue={currentSupplierId}
                        {...register('supplierId')}
                    />

                    <Modal.Header closeButton>
                        <Modal.Title>Criar coordenador</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    placeholder="Nome Completo"
                                    {...register('name', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um Nome.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>CPF</Form.Label>

                                <Form.Control
                                    type="text"
                                    placeholder="Documento (CPF)"
                                    {...register('document', { required: true })}
                                />


                                {errors.document && (
                                    <span className="label-error">
                                        Você precisa informar um CPF.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>E-mail</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="E-mail"
                                    {...register('email', { required: true })}
                                />

                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um E-mail válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Telefone (opcional)</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Telefone com DDD"
                                    {...register('phoneNumber')}
                                    onChange={(event) => {
                                        const { value } = event.target
                                        event.target.value = maskPhone(value)
                                    }}
                                />

                            </Col>

                        </Row>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Fechar
                        </Button>
                        <Button variant="primary" type='submit'>
                            Criar
                        </Button>
                    </Modal.Footer>
                </Form>

            </Modal>
        </>
    )
}
