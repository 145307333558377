import firebase from 'firebase/app';
import { getFirestore, collection, addDoc, where, query, getDocs } from "firebase"
import "firebase";
const firebaseConfig = {
    apiKey: "AIzaSyBpVxHFxhvgpvh1j50PzpQ704e2o1Gyib4",
    authDomain: "bepass-platform.firebaseapp.com",
    projectId: "bepass-platform",
    storageBucket: "bepass-platform.appspot.com",
    messagingSenderId: "563791290358",
    appId: "1:563791290358:web:a3594ba2bdb12613caf1d7",
    measurementId: "G-8RD2QTM676"
};
firebase.initializeApp(firebaseConfig);
// export const db = getFirestore();
const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });
export const auth = firebase.auth();
export default firebase;
