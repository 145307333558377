import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  DropdownButton,
  Dropdown,
  Table,
  Modal
} from 'react-bootstrap'

import { BASE } from '../../basedata/constants'

import graphTest from '../../images/test-graph.png'

import { utils, read, writeFile } from 'xlsx';

interface Props {
  supplierName: any,
  supplierId: any,
  eventId: any
}


import { PiMegaphoneLight } from "react-icons/pi";
import { GoChevronDown } from "react-icons/go";
import { PiMagnifyingGlassLight } from "react-icons/pi";
import { LiaFileDownloadSolid } from "react-icons/lia";
import { LiaTimesSolid } from "react-icons/lia";
import { TbDoorEnter } from "react-icons/tb";
import { BsPen } from "react-icons/bs";
import { AiOutlineUsergroupAdd, AiOutlineForm, AiOutlineMinusCircle } from "react-icons/ai";
import { MdOutlineFileDownload } from "react-icons/md";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { CONFIG } from '../../config/configVariables'
import { FaPen } from 'react-icons/fa'
import { TiPen } from 'react-icons/ti'
import { useForm } from 'react-hook-form';

ChartJS.register(ArcElement, Tooltip, Legend);

export function SupplierUsersCountItemList({ supplierName, supplierId, eventId }: Props) {

  const [supplierStatus, setSupplierStatus] = useState<any>({})


  const dateFormatter = (dateTime:string)=>{

    const options:any = {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };

    const oldDate = new Date(dateTime)
    return oldDate.toLocaleDateString('pt-BR', options).split(',').join(' ')
  }
  
  const getSupplierUsersStatus = () => {
    fetch(`${BASE.EVENT.url}${BASE.EVENT.event_supplier_status}/${eventId}/${supplierId}`, {
      method: 'GET',
    })
      .then((resg) => resg.json())
      .then((response) => {
        setSupplierStatus(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const removeSupplierFromEvent = () => {
    fetch(`${BASE.EVENT.url}${BASE.EVENT.event_remove_supplier}`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          Authorization: CONFIG.API_KEY
      },
      body: JSON.stringify({
          eventId,
          groupId: supplierId
      })
  })
      .then((resg) => resg.json())
      .then((response) => {
        window.location.reload()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    getSupplierUsersStatus()
  }, [])


 
  const data = {
    // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: 'Pessoas',
        data: [supplierStatus.biometryCount || 0, supplierStatus.userCount || 0],
        backgroundColor: [
          'rgba(69, 185, 235, 1)',
          'rgba(180, 180, 180, 0.2)',
        ],
        spacing: 5,
        weight: 8,
        cutout: '80%'
        // clip: {left: 15, top: false, right: -2, bottom: 0}
      },
    ],
 
  };

  const options:any = {
    cutout: {
      percentage: 80, // Adjust this value to control the thickness of the donut ring
    },
  };


  const downloadTickets = (supplierId) => {

    fetch(`${BASE.EVENT.url}${BASE.EVENT.event_report}/${supplierId}`, {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
    },
    })
    .then((resg) => resg.json())
    .then((response) => {

      const downloadList = response.map((item) => {
  
        return {
          nome: `${item.firstName} ${item.lastName}`,
          empresa: item.groupName || 'na',
          entrada: item.entrance !== null ? dateFormatter(item.entranceDate):'--',
          funcao: item.function || 'na',
          setor: item.sector || 'na',
          documento: item.document,
          onboarding: `${BASE.onboarding.base_url}/?token=${item?.biometricToken}`,
          biometria: item.imageStatus
        }
      })

      const worksheet = utils.json_to_sheet(downloadList);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, 'relatorio');
      writeFile(workbook, `relatorio.xlsx`);
    })
    .catch((error) => {
      console.log(error)
    })



  }


  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    getValues,
    reset
} = useForm()

const [showConfirm, setShowConfirm] = useState(false);

const handleShowConfirm = () => setShowConfirm(true)
const handleCloseConfirm = () => setShowConfirm(false)


const editProviderLimit = (data: any) => {


  fetch(`${BASE.EVENT.url}${BASE.EVENT.group_callup}`, {
      method: 'Put',
      headers: {
          'Content-Type': 'application/json',
          'x-api-key': BASE.api.api_key
      },
      body: JSON.stringify({
          eventId: eventId,
          groupId: supplierId,
          limit: data.groupLimit
      })
  })
      .then((res) => res.json())
      .then((response) => {
          alert('Grupo atualizado com sucesso!')
          window.location.reload();
      })
      .catch((error) => {
          console.log(error)
      })


}

  return (
  <li key={supplierId} className='small-numbers'>
    <div className="small-numbers-content">
    <div className="small-chart">
    <div className="chart-values">{Math.round(((supplierStatus.biometryCount || 0) / (supplierStatus.userCount || 0)) * 100) || 0}%</div>
      <Doughnut data={data} />
      <span>Biometrias</span>
    </div>
    <div className='chart-title'>
    {supplierName}
    </div>

    <div className="small-description">
      <div className="small-refference-numbers">
      <div>
      <span className="numbers-value">
          <span className='numbers-value-off'>{supplierStatus.userCount}/</span>{supplierStatus.limit}</span>
        <span>Pessoas</span>
      </div>
      <div>
        <span className="numbers-value">
          <span className='numbers-value-off'>{supplierStatus.biometryCount}/</span>{supplierStatus.userCount}</span>
        <span>Biometrias</span>
      </div>
      </div>
    </div>
    <ul className="group-action">
      <li onClick={() => window.location.href = `/provider/events/${eventId}/${supplierId}`}>
          <span className="embed-icon sidebar-icon"><PiMegaphoneLight/> Convocar</span>
      </li>
      <li onClick={() => downloadTickets(supplierId)}>
          <span className="embed-icon sidebar-icon"> <MdOutlineFileDownload/> Relatório</span>
      </li>

      <li onClick={() => {handleShowConfirm()}}>
          <span className="embed-icon sidebar-icon"> <TiPen/> Editar</span>
      </li>
    </ul>
    <div className='event-ticket-button'>
      <div onClick={removeSupplierFromEvent} className="event-icon">
        excuir
      </div>
    </div>
    </div>

    <Modal show={showConfirm} onHide={handleCloseConfirm}>
                <Form onSubmit={handleSubmit(editProviderLimit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Editar Grupo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-3">
                            <Col>
                                <Form.Label>Limite de convocações</Form.Label>
                                <Form.Control
                                    defaultValue={`${supplierStatus.limit}`}
                                    {...register('groupLimit', { required: true })}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button className='align-right' variant="success" type='submit'>
                            Salvar
                        </Button>
                    </Modal.Footer>
                </Form>

            </Modal>
  </li>
  )
}
