import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table, ProgressBar, Form, Button, InputGroup, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'



import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { useForm } from 'react-hook-form'

function Dashboard() {

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    getValues,
    reset
  } = useForm()


  const [disableSign, setDisableSign] = useState(false)

  const onSubmit = (data: any) => {
    setDisableSign(true)

    const username = data.name

    fetch(`${BASE.api.base_url}${BASE.api.access_create}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': BASE.api.api_key
      },
      body: JSON.stringify({
        name: data.name.replace(/^\s+|\s+$/g, ''),
        email: data.email.replace(/^\s+|\s+$/g, ''),
        login: username,
        password: `AM${Math.random().toString(36).slice(-8)}!`,
        role: data.type || 'default'
      })
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response)

      })
      .catch((error) => {
        console.log(error)
      })
  }

  const resetForm = () => {

    setDisableSign(false)
    reset()
  }

  const [companiesList, setCompaniesList] = useState<any[]>([])

  const [isLoading, setLoading] = useState(false)

  const [dashboardNumbers, setDashboardNumbers] = useState({
    biometries: 0,
    suppliers: 0,
    companies: 0
  })


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [showOrg, setShowOrg] = useState(false);

  const handleShowOrg = () => setShowOrg(true)
  const handleCloseOrg = () => setShowOrg(false)


  const [showConfirm, setShowConfirm] = useState(false);

  const handleShowConfirm = () => setShowConfirm(true)
  const handleCloseConfirm = () => setShowConfirm(false)

  const [currentCompanyId, setCurrentCompanyId] = useState()

  const userSyncProgress = (partialSync: any, totalSync: any) => {
    return Math.round((totalSync / partialSync) * 100);
  }

  const excludeCompany = () => {

    fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.companies}/${currentCompanyId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': BASE.api.api_key
      }
    })
      .then((res) => res.json())
      .then((response) => {
        handleCloseConfirm()
        getCompanies()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getCompanies = () => {
    setLoading(true)
    fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.companies}`, {
      method: 'GET',
    })
      .then((resg) => resg.json())
      .then((response) => {
        setLoading(false)
        setCompaniesList(response.companies)
        setDashboardNumbers({ ...dashboardNumbers, companies: response.total })


      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }

  const getBiometries = () => {
    setLoading(true)
    fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.biometry}/count`, {
      method: 'GET',
    })
      .then((resg) => resg.json())
      .then((response) => {
        setLoading(false)
        setCompaniesList(response.companies)
        setDashboardNumbers({ ...dashboardNumbers, biometries: response.total })


      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }

  const getSuppliers = () => {
    setLoading(true)
    fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.supplier}/count`, {
      method: 'GET',
    })
      .then((resg) => resg.json())
      .then((response) => {
        setLoading(false)
        setCompaniesList(response.companies)
        setDashboardNumbers({ ...dashboardNumbers, suppliers: response.total })


      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }

  useEffect(() => {
    getCompanies()
    getBiometries()
    getSuppliers()
  }, [])

  return (
    <>
      <Row>
        <Col md={4}>
          <Card>
            <Card.Body className="border-bottom">
              <div className="row d-flex align-items-center">
                <div className="col-auto">
                  <i className="feather icon-star f-30 text-c-gray" />
                </div>
                <div className="col">
                  <h3 className="f-w-300">{dashboardNumbers.companies}</h3>
                  <span className="d-block text-uppercase">Empresas cadastradas</span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* <Col md={4}>
          <Card>
            <Card.Body className="border-bottom">
              <div className="row d-flex align-items-center">
                <div className="col-auto">
                  <i className="feather icon-grid f-30 text-c-yellow" />
                </div>
                <div className="col">
                  <h3 className="f-w-300">{dashboardNumbers.suppliers}</h3>
                  <span className="d-block text-uppercase">Grupos Cadastrados</span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card>
            <Card.Body className="border-bottom">
              <div className="row d-flex align-items-center">
                <div className="col-auto">
                  <i className="feather icon-users f-30 text-c-green" />
                </div>
                <div className="col">
                  <h3 className="f-w-300">{dashboardNumbers.biometries}</h3>
                  <span className="d-block text-uppercase">Biometrias Totais</span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col> */}
      </Row>

      <Row>
        <Col sm={12}>
          <Card className="Recent-Users">
            <Card.Header>


              <Form>
                <Row className="align-items-center">
                  <Col sm={12} md={1}>
                    <Card.Title as="h5">Empresas</Card.Title>
                  </Col>
                  <Col sm={12} md={3}>
                    <Form.Label htmlFor="inlineFormInputName" visuallyHidden>
                      Evento
                    </Form.Label>
                    <Form.Control id="inlineFormInputName" placeholder="Nome da empresa" />
                  </Col>
                  <Col sm={12} md={3}>
                    <Form.Label htmlFor="inlineFormInputName" visuallyHidden>
                      Evento
                    </Form.Label>
                    <Form.Control id="inlineFormInputName" placeholder="CNPJ" />
                  </Col>


                  <Col sm={12} md={3} lg={2} >
                    <Button className='w-100' type="submit">Pesquisar</Button>
                  </Col>
                </Row>
              </Form>

            </Card.Header>
            <Card.Body className="px-0 py-2">
              <Table responsive hover>
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Empresa</th>
                    {/* <th className='text-center'>Grupos / Pessoas</th> */}
                    {/* <th>Biometrias</th> */}
                    <th className='text-center'>Ações</th>
                  </tr>
                </thead>
                <tbody>

                  {companiesList &&
                    companiesList.map((item: any, index) => (

                      <tr key={index} className="unread">
                        <td>
                          <h6 className="text-muted biometry-status m-t-10">
                            {item.isActive ? 'ativo' : 'inativo'}
                            <i className={`fa fa-circle text-c-${item.isActive ? 'green' : 'red'} f-12 m-l-15`} />
                          </h6>
                        </td>
                        <td>
                          <h6 className="mb-1">{item.name}</h6>
                        </td>
                        {/* <td className='text-center'>
                          <h6 className="text-muted biometry-status m-t-10">
                            4 / 450</h6>
                        </td> */}


                        {/* <td>

                          {item.biometricTotal && itemSyncProgress(item.biometricTotal, item.biometricLeft) < 100 ?

                            (itemSyncProgress(item.biometricTotal, item.biometricLeft) < 0) ?
                              <ProgressBar variant="danger" label={'erro'} now={100} /> :
                              (<ProgressBar animated label={`${itemSyncProgress(item.biometricTotal, item.biometricLeft)}%`} now={itemSyncProgress(item.biometricTotal, item.biometricLeft)} />) :
                            itemSyncProgress(item.biometricTotal, item.biometricLeft) > 0 ?
                              (
                                <ProgressBar variant="success" label={'completa'} now={100} />
                              ) : ''
                          }
                        </td> */}

                        <td className='text-center'>
                          {/* <Link to="/events/119929755" className="label theme-bg text-white f-12 m-l-15">
                            Grupos
                          </Link>
                          <Link to="#" onClick={() => { handleShowOrg() }} className="label theme-bg2 text-white f-12">
                            Editar
                          </Link> */}
                          <Link to="#" onClick={() => { setCurrentCompanyId(item.id); handleShowConfirm() }} className="label theme-bg4 text-white f-12 m-l-15">
                            excluir
                          </Link>

                        </td>
                      </tr>

                    ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>


      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Criar coordenador</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-3">

              <Col>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  placeholder="Nome Completo"
                  {...register('name', { required: true })}
                />
                {errors.name && (
                  <span className="label-error">
                    Você precisa informar um Nome.
                  </span>
                )}</Col>
            </Row>
            <Row className="mb-3">
              <Col><Form.Label>E-mail (opcional)</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="E-mail"
                  {...register('email')}
                />
                {errors.email && (
                  <span className="label-error">
                    Você precisa informar um E-mail válido
                  </span>
                )}</Col>

            </Row>
            <Row className="mb-3">
              <Col><Form.Label>Telefone (opcional)</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Telefone"
                  {...register('phone')}
                />
                {errors.email && (
                  <span className="label-error">
                    Você precisa informar um Telefone válido
                  </span>
                )}</Col>

            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Button
                    disabled={disableSign}
                    type="submit"
                    className="mb-0 w-100"
                    onClick={() => clearErrors()}
                  >
                    Cadastrar Usuário
                  </Button>
                </Form.Group>
              </Col>
              <Col>
                {disableSign && (
                  <Form.Group>
                    <Button
                      className="mb-0 btn-success w-100"
                      onClick={() => resetForm()}
                    >
                      Cadastrar Outro
                    </Button>
                  </Form.Group>
                )}
              </Col>
            </Row>
            {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Criar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showOrg} onHide={handleCloseOrg}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Evento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-3">

              <Col>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  placeholder="Nome Completo"
                  {...register('name', { required: true })}
                />
                {errors.name && (
                  <span className="label-error">
                    Você precisa informar um Nome.
                  </span>
                )}</Col>
            </Row>
            <Row className="mb-3">
              <Col><Form.Label>Local</Form.Label>
                <Form.Control
                  type="email"
                  placeholder=""
                  {...register('email')}
                />
              </Col>

            </Row>
            <Row className="mb-3">
              <Col><Form.Label>Data/Hora</Form.Label>
                <Form.Control
                  type="email"
                  placeholder=""
                  {...register('phone')}
                />
                {errors.email && (
                  <span className="label-error">
                    Você precisa informar um Telefone válido
                  </span>
                )}</Col>

            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Button
                    disabled={disableSign}
                    type="submit"
                    className="mb-0 w-100"
                    onClick={() => clearErrors()}
                  >
                    Atualizar
                  </Button>
                </Form.Group>
              </Col>

            </Row>
            {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseOrg}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showConfirm} onHide={handleCloseConfirm}>
        <Modal.Body>
          Tem certeza?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirm}>
            Sair
          </Button>
          <Button variant="danger" onClick={excludeCompany}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Dashboard
