import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  DropdownButton,
  Dropdown,
  Table
} from 'react-bootstrap'

import { BASE } from '../../basedata/constants'

import graphTest from '../../images/test-graph.png'

interface Props {
  supplierName: any,
  supplierId: any,
  eventId: any
}


import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export function SupplierUsersCountCard({ supplierName, supplierId, eventId }: Props) {

  const [supplierStatus, setSupplierStatus] = useState<any>({})

  const getSupplierUsersStatus = () => {
    fetch(`${BASE.EVENT.url}${BASE.EVENT.event_supplier_status}/${eventId}/${supplierId}`, {
      method: 'GET',
    })
      .then((resg) => resg.json())
      .then((response) => {
        setSupplierStatus(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    getSupplierUsersStatus()
  }, [])


 
  const data = {
    // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: 'Pessoas',
        data: [supplierStatus.biometryCount || 0, supplierStatus.userCount || 0],
        backgroundColor: [
          'rgba(69, 185, 235, 1)',
          'rgba(180, 180, 180, 0.2)',
        ],
        spacing: 5,
        weight: 8,
        cutout: '80%'
        // clip: {left: 15, top: false, right: -2, bottom: 0}
      },
    ],
 
  };

  const options:any = {
    cutout: {
      percentage: 80, // Adjust this value to control the thickness of the donut ring
    },
  };


  return (
  <li key={supplierId} className='small-numbers col-lg-6 col-xl-3'>
    <div className="small-numbers-content">
    <div className="small-chart">
      <div className="chart-values">{Math.round(((supplierStatus.biometryCount || 0) / (supplierStatus.userCount || 0)) * 100) || 0}%</div>
      <Doughnut data={data} />
      <span className='chart-subtitle'>biometrias</span>
    </div>
    <div className="small-description">
      {supplierName}
      <div className="small-refference-numbers">
      <div>
      <span className="numbers-value">
          <span className='numbers-value-off'>{supplierStatus.userCount}/</span>{supplierStatus.limit}</span>
        <span>Pessoas</span>
      </div>
      <div>
        <span className="numbers-value">
          <span className='numbers-value-off'>{supplierStatus.biometryCount}/</span>{supplierStatus.userCount}</span>
        <span>Biometrias</span>
      </div>
      </div>
    </div>
    <div className='event-ticket-button'>
      <div onClick={() => {window.location.href = `/provider/events/${eventId}/${supplierId}`}} className="event-icon">
        ver
      </div>
    </div>
    </div>
  </li>
  )
}
