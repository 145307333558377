import React, { useState, useEffect } from 'react';
import { Modal, Card, Button} from 'react-bootstrap';
import PropTypes from 'prop-types'; // Importe o PropTypes


const InsertLogoModal = ({ showModal, handleModalClose}) => {

  const [logoFile, setLogoFile] = useState(null);
  const [teamName, setTeamName] = useState('');

  const handleLogoFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLogoFile(file);
    }
  };

  const handleSaveLogo = () => {
    console.log('Arquivo de logo:', logoFile);
    console.log('Nome do time:', teamName);
    handleModalClose();
  };

  return (
    <Modal show={showModal} onHide={handleModalClose}>
      <Modal.Body>
        <Card>
          <Card.Header>
            <Card.Title as="h5">
              Inserir um novo logo
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <div className="mb-4 row align-items-center">
              <label htmlFor="teamName" className="col-sm-2 col-form-label text-dark" >Time:</label>
              <div className="col-sm-9">
                 <input type="text" className="form-control" id="teamName" value={teamName} onChange={(e) => setTeamName(e.target.value)} />
              </div>
            </div>
            <div className="mb-4 row align-items-center">
                <label htmlFor="logoFile" className="col-sm-2 col-form-label text-dark">Logo:</label>
              <div className="col-sm-9">
                 <input type="file" className="form-control" id="logoFile" onChange={handleLogoFileChange} />
              </div>
            </div>
            <div className="text-center">
              <Button className='btn btn-dark' onClick={handleSaveLogo}>Inserir</Button>
            </div>
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

InsertLogoModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
};

export default InsertLogoModal;
