import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  DropdownButton,
  Dropdown,
  Table
} from 'react-bootstrap'

import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'

interface Props {
  gate: any
  sendVisits: (item: any) => void
}

export function GateFlow({ gate, sendVisits }: Props) {
  // const [gateStatus, setGateStatus] = useState(false)
  const [gateFlow, setGateFlow] = useState([
    {
      type: 'visitor',
      count: 0
    },
    {
      type: 'guest',
      count: 0
    },
    {
      type: 'associate',
      count: 0
    },
    {
      type: 'provider',
      count: 0
    },
    {
      type: 'employee',
      count: 0
    }
  ])

  const [gateFlowExit, setGateFlowExit] = useState([
    {
      type: 'visitor',
      count: 0
    },
    {
      type: 'guest',
      count: 0
    },
    {
      type: 'associate',
      count: 0
    },
    {
      type: 'provider',
      count: 0
    },
    {
      type: 'employee',
      count: 0
    }
  ])

  const allGateStatus = (gatesId: any) => {
    let temp_visitor = 0,
      temp_guest = 0,
      temp_associate = 0,
      temp_provider = 0,
      temp_employee = 0,
      temp_visitor_exit = 0,
      temp_guest_exit = 0,
      temp_associate_exit = 0,
      temp_provider_exit = 0,
      temp_employee_exit = 0

    fetch(`${BASE.api.base_url}${BASE.api.get_log_from_gate}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      },
      body: JSON.stringify({ IP: gatesId })
    })
      .then((resg) => resg.json())
      .then((response) => {
        response.map((item: any) => {
          if (item.type === 'visitor') {
            if (item.direction === 'entrance') {
              temp_visitor++
            } else {
              temp_visitor_exit++
            }
          }
          if (item.type === 'guest') {
            if (item.direction === 'entrance') {
              temp_guest++
            } else {
              temp_guest_exit++
            }
          }
          if (item.type === 'associate') {
            if (item.direction === 'entrance') {
              temp_associate++
            } else {
              temp_associate_exit++
            }
          }
          if (item.type === 'supplier') {
            if (item.direction === 'entrance') {
              temp_provider++
            } else {
              temp_provider_exit++
            }
          }
          if (item.type === 'employee') {
            if (item.direction === 'entrance') {
              temp_employee++
            } else {
              temp_employee_exit++
            }
          }
        })

        setGateFlow([
          {
            type: 'visitor',
            count: temp_visitor
          },
          {
            type: 'guest',
            count: temp_guest
          },
          {
            type: 'associate',
            count: temp_associate
          },
          {
            type: 'provider',
            count: temp_provider
          },
          {
            type: 'employee',
            count: temp_employee
          }
        ])

        setGateFlowExit([
          {
            type: 'visitor',
            count: temp_visitor_exit
          },
          {
            type: 'guest',
            count: temp_guest_exit
          },
          {
            type: 'associate',
            count: temp_associate_exit
          },
          {
            type: 'provider',
            count: temp_provider_exit
          },
          {
            type: 'employee',
            count: temp_employee_exit
          }
        ])
        console.log(gateFlow)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    allGateStatus(gate)
  }, [])

  return (
    // <Card className={`Recent-Users ${gateStatus ? 'gate-up' : 'gate-down'}`}>
    //   <Card.Header>
    //     <Card.Title as="h5">
    //       {gateName} | {gateDirection} <br />
    //       <small>{group}</small> | <small>{gateFlowNumber} Pessoas</small>
    //     </Card.Title>
    //   </Card.Header>
    // </Card>
    <>
      <Card.Body className="">
        <Table responsive striped>
          <tbody>
            <tr>
              <td className="pb-0 pt-0 align-right">Associados</td>
              <td className="pb-0 pt-0">
                <i className="feather icon-arrow-up"></i>
                {gateFlow[2].count}
              </td>
              <td className="pb-0 pt-0">
                <i className="feather icon-arrow-down"></i>
                {gateFlowExit[2].count}
              </td>
            </tr>
            <tr>
              <td className="pb-0 pt-0 align-right">Funcionários</td>
              <td className="pb-0 pt-0">
                <i className="feather icon-arrow-up"></i>
                {gateFlow[4].count}
              </td>
              <td className="pb-0 pt-0">
                <i className="feather icon-arrow-down"></i>
                {gateFlowExit[4].count}
              </td>
            </tr>
            <tr>
              <td className="pb-0 pt-0 align-right">Pessoas</td>
              <td className="pb-0 pt-0">
                <i className="feather icon-arrow-up"></i>
                {gateFlow[3].count}
              </td>
              <td className="pb-0 pt-0">
                <i className="feather icon-arrow-down"></i>
                {gateFlowExit[0].count}
              </td>
            </tr>
            <tr>
              <td className="pb-0 pt-0 align-right">Visitantes</td>
              <td className="pb-0 pt-0">
                <i className="feather icon-arrow-up"></i>
                {gateFlow[0].count}
              </td>
              <td className="pb-0 pt-0">
                <i className="feather icon-arrow-down"></i>
                {gateFlowExit[0].count}
              </td>
            </tr>
            <tr>
              <td className="pb-0 pt-0 align-right">Convidados</td>
              <td className="pb-0 pt-0">
                <i className="feather icon-arrow-up"></i>
                {gateFlow[1].count}
              </td>
              <td className="pb-0 pt-0">
                <i className="feather icon-arrow-down"></i>
                {gateFlowExit[1].count}
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer>
        <div className="row align-items-center justify-content-center card-active">
          <div className="col-6">
            <h6 className="text-center m-b-10">
              <span className="text-muted m-r-5">Entraram:</span>{' '}
              {gateFlow[0].count +
                gateFlow[1].count +
                gateFlow[2].count +
                gateFlow[3].count +
                gateFlow[4].count}
            </h6>
          </div>
          <div className="col-6">
            <h6 className="text-center  m-b-10">
              <span className="text-muted m-r-5">Saíram:</span>{' '}
              {gateFlowExit[0].count +
                gateFlowExit[1].count +
                gateFlowExit[2].count +
                gateFlowExit[3].count +
                gateFlowExit[4].count}
            </h6>
          </div>
        </div>
      </Card.Footer>
    </>
  )
}
